

const Footer = () => {
  return (
    <div className='footer'>
      <p>Mup Report | All Rights Reserved &copy; 2024</p>
    </div>

  );
};

export default Footer;