// src/ListComponent.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ListComponent = ({ url, listname,desc, pclass, secondp }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setItems(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div className="list-component">
      <h2>{listname}</h2>
      <p>{secondp}</p>
      <p className={pclass}>{desc}</p>
      {items.map((item, index) => (
        <div key={index} className="list-item">
          <a href={item.url} target='_blank' rel="noreferrer" className="listStyle">{item.title} | <span className='pub'>{item.published}</span></a>
          
        </div>
      ))}
      
    </div>
  );
};

export default ListComponent;
