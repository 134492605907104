
import './App.css';
import ListComponent from './ListComponent';
import Header from './Header';
import Footer from './Footer';

function App() {
  const url1 = 'https://raw.githubusercontent.com/c3nturi0n2013/data/master/week24.json';
  const url2 = 'https://raw.githubusercontent.com/c3nturi0n2013/data/master/odd24.json';
  return (
    <div className="App">
      <Header></Header>
      <div className="content">
        <h1 className='title'>Welcome! 🏈</h1>

      <div className="lists-container">
        
        <ListComponent url={url1} listname={'MUP (MatchUP) Reports'} desc={'**A download link will be emailed to you through Gumroad.'} pclass={'red'}/>
        <ListComponent url={url2} listname={'Sportsbook Odds History Reports'} desc={'The 2024 Season is almost here! 😃'} pclass={'listdesc'} sclass={'pub'} secondp={'Odds History Reports are sold through Gumroad. Week 1 shows a free example.'}/>

      </div>
      
      </div>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
