import logo from './images/mup-logo.png'

const Header = () => {
  return (
    <div className='header'>
        <div className='logo-container'><img src={logo} alt="Mup Report Logo" className="logo"/></div>
      <nav>
       
        <a href="#year24" className='nav-link'>2024</a>

      </nav>
    </div>

  );
};

export default Header;